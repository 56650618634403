"use client";
import { Button, CircularProgress } from "@material-ui/core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  addSeconds,
  differenceInSeconds,
  formatDistanceToNow,
  fromUnixTime
} from "date-fns";
import dayjs from "dayjs";
import createDebug from "debug";
import jwtDecode from "jwt-decode";
import { useRouter } from "next/router";
import { sortBy } from "ramda";
import * as React from "react";

import { UmbrellaAccount } from "~/declarations/models/UmbrellaAccount";
import { User } from "~/declarations/models/User";
import { PearContext } from "~/pages/_app";
import { setCachedFirstSeenDate } from "~/utils/cachedFirstSeenDate";
import { setCachedWeekStart } from "~/utils/cachedWeekStart";
import { anonymousPost, authenticatedGet } from "~/utils/http";
import { segment } from "~/utils/segment";

import styled from "../core/styled";
import AuthLayout from "../layouts/AuthLayout";
import { useShowSnack } from "../layouts/common/SnackLayout";
import { LoginForm, LoginFormResult } from "./LoginForm/LoginForm";

const debug = createDebug("dashboard:auth");

export function useLocalStorage<T>(
  key: string
): [T | undefined, (value: T | undefined) => void] {
  const initial = React.useMemo(() => {
    const storedString = localStorage.getItem(key) ?? undefined;
    if (storedString) {
      try {
        return JSON.parse(storedString) as T;
      } catch (err) {
        localStorage.removeItem(key);
      }
    }
    return undefined;
  }, [key]);
  const [state, setState] = React.useState(initial);
  const setValue = React.useCallback(
    (value: T | undefined) => {
      setState(value);
      if (value) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.removeItem(key);
      }
    },
    [key]
  );
  return [state, setValue];
}

function tokenTTL(token: string): number {
  if (token.startsWith("teacher.")) {
    return Infinity;
  }
  const tokenData: { exp: number } = jwtDecode(token);
  return differenceInSeconds(fromUnixTime(tokenData.exp), new Date());
}

function setIntervalWithGuiding(fn: () => void, timeout: number) {
  const interval = setInterval(fn, timeout);
  fn();
  return interval;
}

const FRESHNESS_TTL = 10 * 60; // in seconds
const FRESHNESS_CHECK_INTERVAL = 60 * 1000; // in ms

export function useTokens() {
  const [tokens, setTokens] = useLocalStorage<[string, string]>("tokens");

  const accessToken = tokens && tokens[0];
  const refreshToken = tokens && tokens[1];

  const isRefreshing = React.useRef(false);

  const accessTTL =
    typeof accessToken !== "undefined" ? tokenTTL(accessToken) : 0;
  const refreshTTL =
    typeof refreshToken !== "undefined" ? tokenTTL(refreshToken) : 0;

  const [refreshPending, setRefreshPending] = React.useState(
    refreshTTL > FRESHNESS_TTL && accessTTL < FRESHNESS_TTL
  );

  React.useEffect(() => {
    if (accessToken && refreshToken) {
      const interval = setIntervalWithGuiding(() => {
        const accessTTL = tokenTTL(accessToken);
        const refreshTTL = tokenTTL(refreshToken);
        if (isFinite(accessTTL) && isFinite(refreshTTL)) {
          debug(
            "access token expires in %s, refresh token expires in %s",
            formatDistanceToNow(addSeconds(new Date(), accessTTL)),
            formatDistanceToNow(addSeconds(new Date(), refreshTTL))
          );
        }
        if (accessTTL < FRESHNESS_TTL) {
          if (!isRefreshing.current && refreshTTL > FRESHNESS_TTL) {
            debug("refresh");
            isRefreshing.current = true;
            anonymousPost("/api/v1/auth/jwt/refresh/", {
              refresh: refreshToken
            })
              .then(response => {
                if (response.status === 200) {
                  debug("set new tokens");
                  setTokens([response.data.access, response.data.refresh]);
                } else {
                  setTokens(undefined);
                }
              })
              .catch(err => {
                debug("refresh failed");
                console.error("refresh error", err);
              })
              .finally(() => {
                isRefreshing.current = false;
                setRefreshPending(false);
              });
          }
        }
      }, FRESHNESS_CHECK_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [accessToken, refreshToken, setTokens]);

  React.useEffect(() => {
    if (accessToken && refreshToken) {
      const interval = setIntervalWithGuiding(() => {
        const refreshTTL = tokenTTL(refreshToken);
        if (refreshTTL < FRESHNESS_TTL && !isRefreshing.current) {
          debug("refresh token stale, unset");
          setTokens(undefined);
          setRefreshPending(false);
        }
      }, FRESHNESS_CHECK_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [accessToken, refreshToken, setTokens]);

  const usableAccessToken =
    accessTTL > 10 && refreshTTL > 10 ? accessToken : undefined;

  return [usableAccessToken, refreshPending, setTokens] as const;
}

type AuthWrapperProps = {
  children: React.ReactElement;
};

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  ...childrenProps
}) => {
  const [accessToken, refreshPending, setTokens] = useTokens();
  const [user, setUser] = useLocalStorage<User>("user");
  const [umbrella, setUmbrella] = useLocalStorage<UmbrellaAccount>(
    "umbrellaAccount"
  );
  const [userLoading, setUserLoading] = React.useState(false);
  const [redirecting, setRedirecting] = React.useState(false);

  const pearContext = React.useContext(PearContext);

  setCachedFirstSeenDate(umbrella ? dayjs(umbrella.firstSeenDate) : undefined);
  setCachedWeekStart(user?.weekStart ?? 1);

  const router = useRouter();
  const showSnack = useShowSnack();

  const setUserData = React.useCallback(
    (user: User) => {
      if (user.umbrellaAccounts.length) {
        user.umbrellaAccounts = sortBy(
          x => x.displayName ?? x.umbrellaAccName,
          user.umbrellaAccounts
        );
        const previouslySelectedName =
          localStorage.getItem("selectedUmbrellaName") ?? undefined;
        setUser(user);
        setUmbrella(
          user.umbrellaAccounts.find(
            x => x.umbrellaAccName === previouslySelectedName
          ) ?? user.umbrellaAccounts[0]
        );
        segment.identify(`${user.id}`);
      } else {
        showSnack.error(
          "This profile isn't associated with any school, talk to Pear Deck Tutor support"
        );
      }
      setUserLoading(false);
      setRedirecting(false);
    },
    [setUser, setUmbrella, setRedirecting, showSnack]
  );

  const getUserData = React.useCallback(
    (accessToken: string) => {
      setUserLoading(true);
      authenticatedGet("/api/v1/account/me/", accessToken, {})
        .then(user => setUserData(user))
        .catch(() => setUserLoading(false));
    },
    [setUserLoading, setUserData]
  );

  const signOut = React.useCallback(() => {
    setTokens(undefined);
    setUser(undefined);
    setUmbrella(undefined);
  }, [setTokens, setUmbrella, setUser]);

  // Called By PearScript - Validate and Redirect to Home
  React.useEffect(() => {
    const { access, refresh } = router.query;
    if (access && refresh) {
      setRedirecting(true);
      setUserLoading(true);
      setUser(undefined);
      setUmbrella(undefined);
      anonymousPost("/api/v1/auth/pear/redirect/", {
        access: typeof access === "string" ? access : access[0],
        refresh: typeof refresh === "string" ? refresh : refresh[0]
      })
        .then(response => {
          if (response.status === 200) {
            const tokens: [string, string] = [
              response.data.access,
              response.data.refresh
            ];
            getUserData(tokens[0]);
            setTokens([tokens[0], tokens[1]]);
            router.push("/");
          } else {
            throw new Error();
          }
        })
        .catch(err => {
          showSnack.error(err.toString() ?? "Unknown error");
          setUserLoading(false);
          setRedirecting(false);
          signOut();
          router.push("/");
        });
    }
  }, [
    showSnack,
    setTokens,
    router.query.access,
    setUser,
    setUmbrella,
    signOut,
    getUserData,
    setRedirecting
  ]);

  React.useEffect(() => {
    if (umbrella) {
      localStorage.setItem("selectedUmbrellaName", umbrella.umbrellaAccName);
    }
  }, [umbrella]);

  React.useEffect(() => {
    if (accessToken && !userLoading) {
      if (!user || !umbrella) {
        getUserData(accessToken);
      }

      if (user && umbrella && pearContext) {
        if (window.pear) {
          window.pear.identifyUser(accessToken);
        }
      }
    }
  }, [accessToken, umbrella, user, userLoading, getUserData, pearContext]);

  const handleLoginFormSubmit = React.useCallback(
    ({ user, tokens }: LoginFormResult) => {
      if (user.umbrellaAccounts.length) {
        setUserData(user);
      } else {
        showSnack.error(
          "This profile isn't associated with any school, talk to Pear Deck Tutor support"
        );
      }
      setTokens(tokens);
    },
    [setTokens, setUserData, showSnack]
  );

  const readRequestHeaders = React.useMemo<HeadersInit>(
    () => ({
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`
    }),
    [accessToken]
  );

  const writeRequestHeaders = React.useMemo<HeadersInit>(
    () => ({
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }),
    [accessToken]
  );

  if (refreshPending || redirecting) {
    return <Preloader />;
  }

  if (!accessToken) {
    return process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID ? (
      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
      >
        <AuthLayout>
          <LoginForm onSubmit={handleLoginFormSubmit} />
        </AuthLayout>
      </GoogleOAuthProvider>
    ) : (
      <AuthLayout>
        <LoginForm onSubmit={handleLoginFormSubmit} />
      </AuthLayout>
    );
  }

  if (!user || !umbrella) {
    return <Preloader />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        accessToken,
        signOut,
        selectedUmbrella: umbrella,
        onSelectUmbrella: setUmbrella,
        readRequestHeaders,
        writeRequestHeaders
      }}
    >
      {React.cloneElement(children, childrenProps)}
    </AuthContext.Provider>
  );
};

export const AuthContext = React.createContext<{
  user: User;
  setUser: (user: User) => void;
  selectedUmbrella: UmbrellaAccount;
  onSelectUmbrella: (value: UmbrellaAccount) => void;
  accessToken: string;
  signOut: () => void;
  readRequestHeaders: HeadersInit;
  writeRequestHeaders: HeadersInit;
}>(undefined as never);

export function useRequiredAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    throw new Error("AuthContext isnt available");
  }
  return context;
}

export function useOptionalAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    return undefined;
  }
  return context;
}

const Preloader: React.FC = () => (
  <ProgressWrapper>
    <CircularProgress variant="indeterminate" />
    <ResetCache />
  </ProgressWrapper>
);

function ResetCache() {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  const reset = React.useCallback(() => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }, []);
  if (!isMounted) {
    return null;
  }
  return <ResetCacheButton onClick={reset}>Reset cache</ResetCacheButton>;
}

const ResetCacheButton = styled(Button)`
  bottom: 10px;
  position: absolute;
  right: 10px;
`;

const ProgressWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;
